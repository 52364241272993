.App {
  text-align: center;
}

.root {
  flexGrow: 1;
  width: 100%;
}

.selectContainer {
  padding-top: 18px;
  padding-bottom: 18px;
}

.select {
  line-height: 41px;
}

.button {
  height: 48px;
}

.component {

}

.divider {
  margin: 20px !important;
}

.view {
  padding: 25px;
}


.form {
  padding: 0 20px 20px 20px;
}

.table {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-color: #1769aa;
  color: white;
}

.tableHead {
}

.tablePaper {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.sortLink:hover {
  color: whitesmoke !important;
}

.tableBody {
  background-color: white;
}

.tabBar {
  background-color: #1769aa !important;
  color: white !important;
}

.tab {
  color: whitesmoke !important;
}

.marked {
  -webkit-box-shadow:inset 7px 0px 0px #aa1768;
  -moz-box-shadow:inset 7px 0px 0px #aa1768;
  box-shadow:inset 7px 0px 0px #aa1768;
}

.alert {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-radius: 4px;
  letter-spacing: 0.01071em;
  background-color: transparent;
  margin-bottom: 10px;
}

.alert.warning {
  color: rgb(102, 60, 0);
  border: 1px rgb(102, 60, 0) solid;
  background-color: rgb(255, 244, 229);
}

.alert.info {
  color: rgb(13, 60, 97);
  border: 1px rgb(13, 60, 97) solid;
  background-color: rgb(232, 244, 253);
}

.alert.error {
  color: rgb(97, 26, 21);
  border: 1px rgb(97, 26, 21) solid;
  background-color: rgb(253, 236, 234);
}

.alert .icon {
  display: flex;
  opacity: 0.9;
  padding: 7px 0;
  font-size: 22px;
  margin-right: 12px;
}

.alert.warning .icon {
  color: #ff9800;
}

.alert.info .icon {
  color: #2196f3;
}

.alert.error .icon {
  color: #f44336;
}

.alert .icon svg {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
}

.alert .message {
  padding: 8px 0;
}

footer {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .displaySmallScreen {
    display: none
  }
}

.cookie-full-screen {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: rgba(35, 87, 128, 0.8);
  background: repeating-linear-gradient(
          45deg,
          rgba(35, 87, 128, 0.8),
          rgba(35, 87, 128, 0.8) 50px,
          rgba(34, 78, 118, 0.8) 50px,
          rgba(34, 78, 118, 0.8) 100px
  );
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-banner {
  z-index: 1000;
  opacity: 1;
  text-align: center;
  color: white;
  padding: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  top: 0;
  background-color: #235780;
  border-radius: 0 0 10px 10px;
}

.cookie-banner .label {
  margin-bottom: 10px;
}

.cookie-button {
  color: white;
  min-width: 200px;
  padding: 10px;
  border: 0.1em solid white;
  background-color: transparent;
  border-radius: 10px;
  margin: 5px;
  font-size: 15px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.cookie-button.accept {
  font-weight: bold;
}

.cookie-button:hover {
  background-color: #194161;
  cursor: pointer;
}

.cookie-banner.active {
  display: block;
}

.cookie-inform-and-ask {
  color: white;
  text-align: center;
  z-index: 1000;
  background-color: #235780;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.cookie-inform-and-ask.active {
  display: block;
}

.cookie-dialog {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
}

footer a {
  color: #1769aa;
  text-decoration: underline #1769aa;
  cursor: pointer;
}
